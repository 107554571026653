@import "~bootstrap/scss/bootstrap";


.container-wrapper {
    width: 100% !important;
    max-width: var(--max-width) !important;

    .footer {
        padding: 6rem 1.2rem;
        min-height: 25.2rem;

        .address {
            h1 {
                font-family: 'Noto Sans';
                margin-bottom: 1rem;
                font-weight: 700;
                font-size: 48px;
                line-height: 65px;
            }

            p {
                font-family: 'Noto Sans';
                margin: 0px;
                padding: 4px 0px;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.9rem;
            }
        }

        .support {
            .sp-title {
                color: #353535;
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.9rem;
                margin-bottom: 1.4rem;
            }

            a {
                text-decoration: none;
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #353535;
            }
        }



        .contact {
            h5 {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 1rem;
            }

            p {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
            }

            svg {
                height: 2.5rem;
                width: 2.5rem;
                padding: 0px 2px;
                color: #353535;
                margin-top: -1rem;
            }

            .input-group {
                display: flex;
                flex-wrap: nowrap;

                .support-form {
                    min-height: 4rem;
                    min-width: 21rem;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                }

                button {
                    width: 6rem;
                    height: 4rem;
                    background-color: #353535;
                    color: #FFFFFF;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 16px;
                }

                button:hover{
                    filter: none;
                }
            }


        }
    }
}