.header-wrapper {
  padding-top: 12px;
  .languge-choices {
    padding: 0 23px;
  }
  span.laguage-type {
    font-size: 14px;
    color: #666;
    display: inline-block;
    margin-left: 12px;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }

  span.hiden-nav-item {
    color: red !important;
  }
  .nav-item {
    font-size: 1.4rem !important;
    font-weight: 300 !important;
    color: #a1a1a1;
    display: inline-block;
    padding: 8px 18px;
    transition: 0.3s;
    vertical-align: center;
    margin-right: 2px;
    height: 40px;
    border-radius: 1px;
    text-decoration: none;

    &:hover {
      color: #eee;
      background-color: #333;
    }
  }

  .nav-item.active {
    color: #eee;
    background-color: #333;
  }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #222;
}
