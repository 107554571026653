.manager-header-wrapper {
  height: 66px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

  div {
    box-shadow: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  width: 100%;

  .the-header {
    padding: 0rem 1.2rem 0rem 26.8rem;
    background-color: #ffffff !important;
    color: #000000 !important;
    height: 100%;
    width: 100%;

    .tool-bar {
      display: flex;
      align-items: center;
      height: 100%;

      .search-box {
        :focus-within {
          border: none;
        }

        display: flex;
        justify-content: start;
        align-items: center;
        height: 40px;

        .search-group {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #e8e8e8;
          border-radius: 25px;
          width: 60rem;
          height: 100%;

          .search-icon {
            height: 23px;
            width: 40px;
            padding: 0px 5px;
            color: #4e4e4e;
            margin-top: 5px;
          }

          .search-inp {
            width: 100%;
            margin-right: 10px;
            font-family: Montserrat;
            font-size: 1.4rem;
            line-height: 17px;
            color: #4e4e4e;
          }
        }

        .search-group:focus-within {
          border: 2px solid #444;
        }
      }

      .avatar-notification_group {
        display: flex;
        justify-content: end;

        .avatar-notification_box {
          .notification_group {
            padding: 8px 12px;

            svg {
              height: 21px;
              width: 21px;
              color: #939393;
            }

            span {
              font-size: 1rem;
            }
          }

          .avatar_group {
            .avatar {
              height: 36px;
              width: 36px;
              padding: 0px;
            }

            padding: 0px;
            margin: 0px 0px 0px 12px;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
        }
      }
    }
  }
}

.notification-menu {
  ul {
    width: 28rem;

    .notification_group {
      padding: 8px 10px;

      .avatar {
        height: 36px;
        width: 36px;
      }

      .notification-content {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 16px;
        color: #292929;
        margin: 0px 14px;
        width: 204px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
      }
    }
  }
}

.profile-menu {
  ul {
    padding: 8px;
  }
  li {
    font-family: Montserrat;
    font-size: 1.4rem;
    line-height: 17px;
    color: #575757;
    padding: 8px 16px;
  }
  li:hover {
    background-color: #ebebeb;
    color: #000000;
  }
  a {
    text-decoration: none;
  }
  .profile_group {
    .avatar {
      height: 36px;
      width: 36px;
      padding: 0px;
    }
    h6 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 20px;
      color: #000000;
    }
    p {
      font-size: 1rem;
      line-height: 12px;
    }
  }
}
